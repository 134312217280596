<template>
  <div class="home" v-loading="loading">
    <swiper id="swiperBox" class="swiper_sty" v-bind:options="swiperOption" :slides-per-view='slidesperview'
      ref="mySwiper">
      <swiper-slide class="carousel-one slide-one">
        <div class="slogan">
          <div>基于量子安全的智能云服务平台</div>
          <div class="home-des home-title">Intelligent Cloud Service Platform Based <br /> on Quantum Security</div>
        </div>
      </swiper-slide>
      <!-- 关于我们 -->
      <swiper-slide class="carousel-slide slide-four">
        <div class="content-summary">
          <div class="content">
            <Headline title="关于我们" subtitle="ABOUT US"></Headline>
            <div class="summary">
              <h5>江苏微知量子科技有限公司</h5>
              <span class="content">
                致力成为量子信息产业生态链中的一环，为政府、企业提供量子信息技术综合解决方案。公司在网络的量子安全升级、量子安全的密码云/数据云/算力云，以及融合量子计算与经典计算的战略混合集群等领域，能够提供整体解决方案和相应的硬件设备与软件平台、关键设备与产品，公司被列入了“无锡市量子科技企业图谱”，为政府、企业的数据全生命周期安全保驾护航。
              </span>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 产品 -->
      <swiper-slide class="carousel-slide slide-two">
        <Headline title='产品' subtitle="PRODUCT"></Headline>
        <div class="product" id="twoo">
          <div class="product_left">
            <h2>研发量子安全的数据采集和存储产品,建设量子安全数据云</h2>
            <div class="left_p">
              <p>根据业务动态调度算力，提升运营效率</p>
              <p>协同边缘节点，提升实时性和降低网络负荷</p>
              <p>分布式计算中心，降低单一节点能耗</p>
              <p>灵活部署加速板卡，提升AI算力</p>
              <p>基于AI加速卡 + QSS加密的高性能服务器</p>
            </div>
          </div>
          <div class="product_right">
            <div class="top_bottom">
              <div class="con_one">
                <h2>网络的量子安全升级</h2>
                <div class="div_p">
                  <p>光纤网络升级</p>
                  <p>量子安全虚拟专网升级</p>
                  <p>量子安全移动服务</p>
                </div>
              </div>
              <div class="con_two">
                <h2>构建量子密码云</h2>
                <div class="div_p">
                  <p>市级密钥管理服务平台组网</p>
                </div>
              </div>
            </div>
            <div class="top_bottom" style="margin-top: 18px;">
              <div class="con_three">
                <h2>高性能智能计算技术 构建量子安全算力云</h2>
                <div class="div_p">
                  <p>生物医药企业、智算中心、科研机构等 拷贝</p>
                </div>
              </div>
              <div class="con_four">
                <h2>量子-经典混合集群方案建设</h2>
                <div class="div_p">
                  <p>生物医药企业、智算中心、科研机构等</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </swiper-slide>
      <!-- 服务 -->
      <swiper-slide class="carousel-slide slide-three">
        <Headline title='服务' subtitle="SERVICE"></Headline>
        <div class="service">
          <el-row class="service_con">
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>01</h4>
                    <p>为政府、企业提供量子安全密码服务</p>
                  </div>
                  <div class="frame_bottom">
                    建设量子密钥分发网络、低成本升级现有线路为量子安全等级；为用户提供量子密码服务，助力用户信息系统达到等保、密评的要求。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>02</h4>
                    <p>为企业、计算中心提供高安全云部署方案、 建设及运维服务</p>
                  </div>
                  <div class="frame_bottom">
                    结合量子安全与高效节能的特点，为客户打造绿色环保安全的公有云/私有云。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>03</h4>
                    <p>为客户提供量子安全高性能计算服务</p>
                  </div>
                  <div class="frame_bottom">
                    提供每秒运算亿亿级的融合量子安全的高性能智能算力公共服务平台。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>04</h4>
                    <p>为客户提供量子安全的分布式云存储服务</p>
                  </div>
                  <div class="frame_bottom">
                    量子安全与分布式存储技术，确保用户数据的私密性、安全性；打消用户数据上云顾虑。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>05</h4>
                    <p>与量子计算机结合形成混合型计算集群</p>
                  </div>
                  <div class="frame_bottom">
                    参与长三角量子科技产业创新中心等相关工作，提供混合型计算集群平台和经典算力服务,为政府和企业提供精细化管理咨询服务。
                  </div>
                </div>
              </div>
            </el-col>
            <el-col :xs="24" :sm="24" :md="12" :lg="12" :xl="12">
              <div class="content">
                <div class="frame">
                  <div class="frame_top">
                    <h4>06</h4>
                    <p>为政府和企业提供精细化管理咨询服务</p>
                  </div>
                  <div class="frame_bottom">
                    结合量子安全与高效节能的特点，为客户打造绿色环保安全的公有云/私有云。
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>

        </div>
      </swiper-slide>
      <!-- 合作伙伴 -->
      <swiper-slide class="carousel-slide slide-five" style="justify-content: space-between;">
        <div class="content-partner">
          <div class="partner">
            <Headline title="合作伙伴" subtitle="RARTNERS"></Headline>
            <div class="img_sty">
              <el-row class="img_row">
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz1.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz2.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz3.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz4.png" alt style="width: 150px" />
                </el-col>
              </el-row>
              <el-row class="img_row">
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz5.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz6.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz7.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz9.png" alt style="width: 150px" />
                </el-col>
              </el-row>
              <el-row class="img_row">
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz10.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz11.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6" class="img_col">
                  <img src="@/assets/images/hz12.png" alt style="width: 150px" />
                </el-col>
                <el-col :span="6">
                  <!-- <img src="@/assets/images/hz8.png" alt style="width: 150px" /> -->
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
        <div class="footersty">
          <div class="footer-content">
            <div class="logo default">
              <img src="../assets/images/logo2.png" alt />
              <p style="display: flex;align-items: center;">
                <span style="position: relative;width:1.2rem;padding-top: 1.2rem;margin-right:5px">
                  <img src="@/assets/images/beian-gov.png" alt=""
                    style="position: absolute;top: 0;left: 0;width: 100%;height: 100%;padding:0px">
                </span>
                <a href="https://beian.mps.gov.cn/#/query/webSearch?code=32020602001674"
                  target="_blank">苏公网安备32020602001674</a>
              </p>
              <p><a href="https://beian.miit.gov.cn/" target="_blank">苏ICP备2022049255号-1</a></p>
            </div>
            <ul class="content-nav">
              <li>
                <p @click="handleSelect({ name: '0' })" class="pointer">首页</p>
              </li>
              <li>
                <p @click="handleSelect({ name: '1' })" class="pointer">关于我们</p>
              </li>
              <li>
                <p @click="handleSelect({ name: '2' })" class="pointer">产品</p>
              </li>
              <li>
                <p @click="handleSelect({ name: '3' })" class="pointer">服务</p>
              </li>
              <li>
                <p @click="handleSelect({ name: '4' })" class="pointer">合作伙伴</p>
              </li>
              <li>
                <p class="default">新闻</p>
              </li>
              <li class="default">
                <p>联系我们</p>
                <!-- <span>邮箱：chaoliu@superfluidity.com.cn</span> -->
                <span>联系人：姜女士</span>
                <span>联系电话：19965806910</span>
                <span>地址：无锡市惠山区生科路10-2号</span>
              </li>
            </ul>
          </div>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>
 
<script>
import store from '@/store'
import { swiper, swiperSlide } from "vue-awesome-swiper";
export default {
  name: "Home_index",
  components: {
    Headline: () => import('../components/headline'),
    swiper,
    swiperSlide,
  },
  data() {
    return {
      loading: false,
      slidesperview: 1,
      swiperOption: {
        notNextTick: true, //notNextTick是一个组件自有属性，如果notNextTick设置为true，组件则不会通过NextTick来实例化swiper，也就意味着你可以在第一时间获取到swiper对象，假如你需要刚加载遍使用获取swiper对象来做什么事，那么这个属性一定要是true
        direction: "vertical", //水平方向移动
        grabCursor: true, //鼠标覆盖Swiper时指针会变成手掌形状，拖动时指针会变成抓手形状
        setWrapperSize: true, //Swiper使用flexbox布局(display: flex)，开启这个设定会在Wrapper上添加等于slides相加的宽或高，在对flexbox布局的支持不是很好的浏览器中可能需要用到。
        autoHeight: true, //自动高度。设置为true时，wrapper和container会随着当前slide的高度而发生变化
        slidesPerView: 1, //设置slider容器能够同时显示的slides数量(carousel模式)。可以设置为数字（可为小数，小数不可loop），或者 'auto'则自动根据slides的宽度来设定数量。loop模式下如果设置为'auto'还需要设置另外一个参数loopedSlides。
        mousewheel: true, //开启鼠标滚轮控制Swiper切换。可设置鼠标选项，默认值false
        mousewheelControl: true, //同上
        height: window.innerHeight - 60, // 高度设置，占满设备高度
        resistanceRatio: 0, //抵抗率。边缘抵抗力的大小比例。值越小抵抗越大越难将slide拖离边缘，0时完全无法拖离。本业务需要
        observeParents: true, //将observe应用于Swiper的父元素。当Swiper的父元素变化时，例如window.resize，Swiper更新

        // 如果自行设计了插件，那么插件的一些配置相关参数，也应该出现在这个对象中，如下debugger
        //debugger: true,

        // swiper的各种回调函数也可以出现在这个对象中，和swiper官方一样
        on: {
          // 监听滑动切换事件，返回swiper对象
          slideChange: () => {
            let swiper = this.$refs.mySwiper.swiper;
            // console.log(swiper.activeIndex); //滑动打印当前索引

            // 调用vuex的获取模块参数
            store.dispatch('GetHomePager', swiper.activeIndex + '').then(() => {
              // console.log(this.homePage,'滚动切换');
            })
            // if (swiper.activeIndex === this.list.length - 1) {
            //   //到最后一个加载更多数据
            //   let newList = [];
            //   let listLength = this.list.length;
            //   for (let i = 0; i < 10; i++) {
            //     newList.push(listLength + i);
            //   }
            //   this.list = this.list.concat(newList);
            // }
          }
        }
      }
    };
  },
  computed: {
    // 如果你需要得到当前的swiper对象来做一些事情，你可以像下面这样定义一个方法属性来获取当前的swiper对象，同时notNextTick必须为true
    swiper() {
      return this.$refs.mySwiper.swiper;
    },
    // 获取vuex数据
    homePage() {
      return this.$store.state.page.homePage
    },
  },
  watch: {
    homePage(newData, oldData) {
      this.$refs.mySwiper.swiper.slideTo(Number(newData), 1000, true);
    }
  },
  mounted() {
    this.$refs.mySwiper.swiper.slideTo(Number(this.homePage), 1000, true);
  },
  methods: {
    handleSelect(key) {
      // this.activeName = key.name;
      let url = key.name;
      switch (url) {
        case '0':
        case '1':
        case '2':
        case '3':
        case '4':
          // this.$router.push('/')
          // localStorage.setItem('locationUrl', '/');
          // this.isShow = false;
          // 调用vuex的获取模块参数
          store.dispatch('GetHomePager', url).then(() => {
            // console.log(this.homePage,'切换');
          })
          break;
        default:
          this.$router.push(url);
          localStorage.setItem('locationUrl', url);
        // this.isShow = true;
      }
    },
  },
};
</script>
 
<style lang="scss" scoped>
::v-deep .el-carousel__button {
  background-color: #c0c0c0;
}

.swiper_sty {
  height: 'calc(100vh - 60px)' !important;
}

.slide-one {
  background: url(../assets/images/index2.png) no-repeat center;
  background-size: cover;
}

.slide-two {
  // background: url(../assets/img/home_do.jpg) no-repeat center;
  // background-size: cover;
}

.slide-three {
  // background: url(../assets/img/home_anli.jpg) no-repeat center;
  // background-size: cover;
}

.slide-four {}

.slide-five {
  background-color: #eff4ff;
}

/*top页*/
.carousel-one {
  height: 100%;
  font-size: 24px;

  .slogan {
    height: 100%;
    font-size: 86px;
    font-weight: 400;
    font-family: Source Han Sans CN;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 12vw;

    .home-title {
      color: #615bfd;
    }

    .home-des {
      font-size: 36px;
      margin-top: 61px;
    }
  }
}

// 公共样式
.carousel-slide {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//产品
.product {
  display: flex;
  margin-top: 90px;

  .product_left {
    width: 390px;
    height: 418px;
    font-family: Source Han Sans CN;
    margin-right: 18px;
    background: url(../assets/images/chan_pin1.png) no-repeat center;
    background-size: cover;

    h2 {
      line-height: 40px;
      font-size: 28px;
      font-weight: bold;
      color: #333333;
      margin: 24px;
    }

    .left_p {
      line-height: 36px;
      font-size: 18px;
      font-weight: 400;
      color: #666666;
      margin: 0 24px;
    }
  }

  .product_right {
    width: 878px;
    height: 418px;

    .top_bottom {
      display: flex;

      .con_one,
      .con_two,
      .con_three,
      .con_four {
        width: 430px;
        height: 200px;
        border-radius: 10px;

        h2 {
          line-height: 28px;
          font-size: 28px;
          font-weight: bold;
          color: #333333;
          margin: 24px;
        }

        .div_p {
          line-height: 36px;
          font-size: 18px;
          font-weight: 400;
          color: #666666;
          margin: 0 24px;
        }
      }

      .con_one {
        margin-right: 18px;
        background: url(../assets/images/chan_pin2.png) no-repeat center;
        background-size: cover;
      }

      .con_two {
        background: url(../assets/images/chan_pin3.png) no-repeat center;
        background-size: cover;
      }

      .con_three {
        margin-right: 18px;
        background: url(../assets/images/chan_pin4.png) no-repeat center;
        background-size: cover;
      }

      .con_four {
        background: url(../assets/images/chan_pin5.png) no-repeat center;
        background-size: cover;
      }
    }
  }
}

// 服务
.service {
  margin-top: 40px;
  max-width: 1240px;

  .service_con {
    .el-col {
      padding: 10px 20px;

      .content {
        border-radius: 5px;
        box-shadow: 0 0 10px #7D7D7D;

        .frame {
          width: 100%;
          height: 200px;
          margin-bottom: 18px;

          // background: url(../assets/images/fuwu1.png) no-repeat center;
          // background-size: cover;
          .frame_top {
            height: 70px;
            display: flex;
            background: #3370f3;
            position: relative;

            h4 {
              width: 60px;
              height: 70px;
              line-height: 70px;
              font-size: 40px;
              font-family: PangMenZhengDao;
              font-weight: 400;
              color: #EFF4FF;
              opacity: 0.45;
              padding-left: 10px;
            }

            p {
              flex: 1;
              font-size: 22px;
              font-family: Source Han Sans CN;
              font-weight: 400;
              color: #EFF4FF;
              padding-right: 10px;
              position: absolute;
              top: 50%;
              left: 70px;
              transform: translateY(-50%);
              -ms-transform: translateY(-50%);
              -o-tranform: translateY(-50%);
              -webkit-transform: translateY(-50%);
              -moz-transform: translateY(-50%);
            }
          }

          .frame_bottom {
            margin: 10px;
            line-height: 36px;
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #7D7D7D;
          }
        }
      }
    }
  }

}

// 关于我们
.content-summary {

  // background-color: #eff4ff;
  .content {
    width: 1240px;
    margin: 0 auto;
    padding: 100px 0;

    .summary {
      margin: 0 auto;
      margin-top: 70px;
      font-family: Source Han Sans CN;

      h5 {
        font-size: 48px;
        color: #3471F3;
        line-height: 60px;
        display: contents;
      }

      .content {
        font-size: 25px;
        color: #333333;
        line-height: 60px;
      }
    }
  }
}

//合作伙伴
.content-partner {
  padding: 70px 0;
  height: calc(100vh - 420px);
  display: flex;
  align-items: center;

  .partner {
    width: 1240px;
    margin: 0 auto;

    .img_sty {
      margin-top: 70px;

      .img_row {
        display: flex;
        justify-content: center;

        .img_col {
          width: 280px;
          height: 100px;
          line-height: 70px;
          text-align: center;
          padding: 20px;
          margin: 15px;
          background: url(../assets/images/white.png) no-repeat center;
          background-size: cover;
        }
      }

      img {
        width: 200px;
      }
    }
  }
}


.order {
  order: -1;
}

.order-img {
  order: 1;
}

// 底部栏
.footersty {
  width: 100%;
  height: 216px;
  overflow: hidden;
  background-color: #14679f;

  .footer-content {
    width: 1240px;
    margin: 0 auto;
    padding-top: 20px;
    display: flex;
    justify-content: space-between;

    .content-nav {
      display: flex;
      justify-content: space-around;

      li {
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        //justify-content: center;
        align-items: flex-start;

        p {
          font-size: 20px;
          color: #d4edff;
          padding: 10px 0;
        }

        .pointer {
          cursor: pointer;
        }

        span {
          color: #f7f7f7;
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }

    .logo {
      img {
        width: 171px;
        height: 53px;
        padding: 10px;
      }

      p {
        margin-left: 10px;
        color: #d4edff;

        span {
          color: #f7f7f7;
        }

        a {
          color: #d4edff;
        }
      }
    }
  }
}

.default {
  cursor: default;
}</style>